<template>
  <eden-container>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <eden-periods
          :default-period="'thisweek'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="getOrderedCombo"
        />
      </template>
    </eden-table-actions>
    <el-table :data="combos[range]" height="250">
      <el-table-column>
        <template slot="header">
          <span>Combo</span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{ formatMealName(scope.row.specifics) }}</span>
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot="header">
          <span>Count</span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{ scope.row.total }}</span>
        </template>
      </el-table-column>
    </el-table>
  </eden-container>
</template>

<script>
import dashboard from "@/requests/dashboard/meal.js";
export default {
  name: "LovedCombos",
  props: {
    range: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      combos: {
        "most-ordered-combo": [],
        "least-ordered-combo": [],
      },
      defaultPeriod: "thisweek",
      periods: {
        lastmonth: "Last month",
        lastweek: "Last week",
        thisweek: "This week",
        thismonth: "This month",
      },
    };
  },
  computed: {
    title() {
      return `${this.formatText(this.range === "most-ordered-combo" ? "most" : "least")} Ordered Combo ${
        this.range === "most-ordered-combo" ? "❤️" : "😔"
      } `;
    },
  },
  created() {
    this.getOrderedCombo({period: this.defaultPeriod })
  },
  methods: {
    getOrderedCombo({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .orderedCombo(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.combos = data;
            this.defaultPeriod = period;
          }
          this.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.combos-cta {
  .el-button {
    &--text {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
}
</style>
