<template>
  <div class="pending-orders">
    <div class="pending-orders--heading">
      <p>{{ title }}</p>
      <el-dropdown :hide-on-click="false" @command="setGardener">
        <el-button type="plain">
          <span> {{ selectedGardener.name }}</span>
          <i class="eden-icon-arrow-down" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <div class="dropdown-search" v-loading="loading">
            <div class="dropdown-search--field">
              <el-input
                v-model="search"
                type="text"
                :prefix-icon="'el-icon-search'"
                placeholder="Search"
              />
            </div>
            <div class="dropdown-search--list">
              <el-dropdown-item
                v-for="(gardener, index) in gardeners"
                :key="index"
                :command="gardener"
                :class="{ selected: gardener.id === selectedGardener.id }"
              >
                <div>
                  <span>{{ gardener.name }}</span>
                  <i
                    v-if="gardener.id === selectedGardener.id"
                    class="eden-icon-check"
                  ></i>
                </div>
              </el-dropdown-item>
            </div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-for="order in orders" :key="order.order_id" class="pending-orders--order">
      <router-link
        :to="{ name: 'orders.order', params: { id: order.order_id } }"
        >{{ order.order_id }}</router-link
      >
      <div>
        <p>{{ order.customer_name }}</p>
        <span class="font-sm">{{ order.customer_gardener }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import dashboard from "@/requests/dashboard/meal";

export default {
  name: "DashboardMealPendingOrders",
  data() {
    return {
      loading: false,
      search: "",
      selectedGardener: {
        id: "all",
        name: "All Gardeners",
      },
      orders: [],
    };
  },
  computed: {
    title() {
      return `${this.orders.length} Orders Yet To Be Dispatched 🚨`;
    },
    gardeners() {
      let gardeners = this.parseData(this.$store.getters.gardeners_list);
      gardeners.unshift({
        id: "all",
        name: "All Gardeners",
      });
      if (this.search !== "") {
        // console.log(this.search, this.selectedGardener.id, "nothubf")
        return gardeners.filter((gardener) =>
          gardener.name.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return gardeners;
      }
    },
  },
  created() {
    if (!this.$store.getters.gardeners_list.length) {
      this.getGardenersList();
    }
    this.getUndispatchedOrder()
    // this.getUndispatchedGardenerOrder()
  },
  methods: {
    getGardenersList() {
      this.loading = true;
      this.$store.dispatch(actions.GET_GARDENERS_LIST).then(() => {
        this.loading = false;
      });
    },
    setGardener(gardener) {
      this.selectedGardener.id = gardener.id;
      this.selectedGardener.name =
        gardener.id === "all" ? gardener.name : `Gardener: ${gardener.name}`;
    },
    getUndispatchedOrder() {
      this.loading = true;
      dashboard
        .pendingOrder()
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.orders = data
          }
          this.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },

    // getUndispatchedGardenerOrder() {
    //   this.loading = true;
    //   dashboard
    //   console.log(this.gardener.id)
    //     .pendingGardenerOrder(this.selectedGardener.id)
    //     .then((response) => {
    //       const { status, data } = response.data;
    //       if (status) {
    //         console.log(status)
    //         this.orders = data
    //       }
    //       this.loading = false;
    //     })
    //     .catch(() => {
    //       this.$message.error("Error fetching pending orders");
    //     });
    // }
  },
};
</script>

<style lang="scss" scoped>
.pending-orders {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid var(--eden-grey-septenary);
  border-radius: 8px;

  &--heading {
    grid-area: 1 / 1 / 2 / 4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    p {
      color: var(--eden-grey-primary);
      font-size: 1rem;
      font-weight: 500;
    }
  }

  &--order {
    display: grid;
    align-items: center;
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 3fr;
    padding: 16px;
    border: 1px solid var(--eden-grey-septenary);
    border-left: 0;
    border-bottom: 0;

    &:nth-child(4) {
      border-right: 0;
    }

    a {
      font-size: 0.925rem;
      color: var(--eden-green-primary);
    }

    p {
      font-size: 0.925rem;
    }

    span {
      color: var(--eden-grey-tertiary);
    }
  }
}

.el-dropdown-menu__item {
  line-height: 1.4;
  padding: 10px 20px;
  font-size: 0.75rem;

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    i {
      color: var(--eden-green-primary) !important;
    }
  }
}

.dropdown-search {
  &--field {
    padding: 0 10px 10px;
  }

  &--list {
    max-height: 250px;
    overflow-y: scroll;
  }
}
</style>
