<template>
  <eden-container>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <eden-periods
          :default-period="'thisweek'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="getComplementaryMeals"
        />
      </template>
    </eden-table-actions>
    <el-table :data="pageData">
      <el-table-column width="100">
        <template slot="header">
          <span>Order Id</span>
        </template>
        <template slot-scope="scope">
          <router-link
            class="text-primary"
            :to="{
              name: 'orders.order',
              params: { id: scope.row.id },
            }"
          >
            {{ scope.row.id }}</router-link
          >
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span>Meal</span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{ scope.row.specifics ? formatMealName(scope.row.specifics.toString()) :  "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column width="300">
        <template slot="header">
          <span>Reason</span>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.reason_for_complementary }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot="header">
          <span>Delivery Date</span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{
            formatDate(scope.row.deliver_to_customer, "do m, y")
          }}</span>
        </template>
      </el-table-column>
    </el-table>
  </eden-container>
</template>

<script>
import dashboard from "@/requests/dashboard/meal";

export default {
  name: "ComplementaryMeals",
  data() {
    return {
      loading: false,
      defaultPeriod: "thisweek",
      periods: {
        lastweek: "Last week",
        yesterday: "Yesterday",
        today: "Today",
        thisweek: "This week",
      },
      page: 1,
      pageData: [],
    };
  },
  computed: {
    title() {
      return `${this.pageData.length} Complementary Meals`;
    },
  },
  created() {
    this.getComplementaryMeals({period: this.defaultPeriod})
  },
  methods: {
    getComplementaryMeals({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .complementaryOrder(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.pageData = data;
            this.defaultPeriod = period;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
