<template>
  <div class="dashboard-meal">
    <el-row type="flex">
      <el-col :span="24">
        <dashboard-meal-pending-orders />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <eden-page-stats
          class="my-0"
          :loading="overview.loading"
          :title="'Overview'"
          :stats="stats"
          :show-periods="true"
          :periods="periods"
          @set-period="getOverview"
        />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <eden-page-stats
          class="my-0"
          :loading="overview.loading"
          :title="'Event Times'"
          :stats="events"
          :show-periods="true"
          :periods="periods"
          :size="'small'"
          @set-period="getEventTimes"
        />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <dashboard-service-feedback :service="'meal'" />
      </el-col>
    </el-row>
    <el-row type="flex" class="is-flex-wrap" :gutter="30">
      <el-col :sm="24" :md="12">
        <loved-combos :range="'most-ordered-combo'" />
      </el-col>
      <el-col :sm="24" :md="12">
        <loved-combos :range="'least-ordered-combo'" />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <complementary-meals />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <unresolved-meals-issue />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DashboardServiceFeedback from "@/components/Dashboard/DashboardServiceFeedback";
import LovedCombos from "@/components/Dashboard/Meal/LovedCombos";
import ComplementaryMeals from "@/components/Dashboard/Meal/ComplementaryMeals";
import UnresolvedMealsIssue from "@/components/Dashboard/Meal/UnresolvedMealsIssue";
import DashboardMealPendingOrders from "@/components/Dashboard/Meal/DashboardMealPendingOrders";
import dashboard from "@/requests/dashboard/meal";

export default {
  name: "DashboardMeal",
  components: {
    DashboardMealPendingOrders,
    DashboardServiceFeedback,
    LovedCombos,
    ComplementaryMeals,
    UnresolvedMealsIssue,
  },
  data() {
    return {
      loading: false,
      eventSummary: {},
      overviewPeriod: "today",
      eventTimesPeriod: "today",
      periods: {
        today: "Today",
        lastweek: "Last week",
        thismonth: "This month",
      },
      overview: {
        loading: false,
        data: {},
      },
    };
  },
  computed: {
    stats() {
      const overview = this.overview.data;
      return [
        {
          label: "Total food subscribers",
          figure: this.formatFigure(overview.total_food_subscribers),
        },
        {
          label: "Subscribers served",
          figure: this.formatFigure(overview.total_subscribers_served),
        },
        {
          label: "One-time customers served",
          figure: this.formatFigure(overview.total_one_time_orders_served),
        },
        {
          label: "Total orders",
          figure: this.formatFigure(overview.total_orders),
        },
        {
          label: "One-time orders",
          figure: this.formatFigure(overview.total_one_time_orders),
        },
        {
          label: "Total juices",
          figure: this.formatFigure(overview.total_juices),
        },
        {
          label: "Total meals",
          figure: this.formatFigure(overview.total_meals),
        },
        {
          label: "Lite plan orders",
          figure: this.formatFigure(overview.total_lite_plan_orders),
        },
        {
          label: "Basic plan orders",
          figure: this.formatFigure(overview.total_basic_plan_orders),
        },
        {
          label: "Standard plan orders",
          figure: this.formatFigure(overview.total_standard_plan_orders),
        },
      ];
    },
    events() {
      const summary = this.eventSummary;
      return [
        {
          label:
            this.eventTimesPeriod === "today"
              ? "Stove off time"
              : "Avg. stove off time",
          figure:
            summary.average_stove_off_time === "No Data"
              ? summary.average_stove_off_time
              : this.formatTime(summary?.average_stove_off_time, "12hr"),
        },
        {
          label:
            this.eventTimesPeriod === "today"
              ? "Last meal plated"
              : "Avg. last meal plated",
          figure:
            summary?.average_plating_time === "No Data"
              ? summary?.average_plating_time
              : this.formatTime(summary?.average_plating_time, "12hr"),
        },
        {
          label:
            this.eventTimesPeriod === "today"
              ? "Last meal sealed"
              : "Avg. last meal sealed",
          figure:
            summary?.average_sealing_time === "No Data"
              ? summary?.average_sealing_time
              : this.formatTime(summary.average_sealing_time, "12hr"),
        },
        {
          label:
            this.eventTimesPeriod === "today"
              ? "Last meal sleeved"
              : "Avg. last meal sleeved",
          figure:
            summary?.average_sleeving_time === "No Data"
              ? summary?.average_sleeving_time
              : this.formatTime(summary.average_sleeving_time, "12hr"),
        },
        {
          label:
            this.eventTimesPeriod === "today"
              ? "Last meal packed"
              : "Avg. last meal packed",
          figure:
            summary?.average_packed_time === "No Data"
              ? summary?.average_packed_time
              : this.formatTime(summary.average_packed_time, "12hr"),
        },
        {
          label:
            this.eventTimesPeriod === "today"
              ? "Last meal dispatched"
              : "Avg. last meal dispatched",
          figure:
            summary?.average_dispatched_time === "No Data"
              ? summary?.average_dispatched_time
              : this.formatTime(summary.average_dispatched_time, "12hr"),
        },
      ];
    },
  },
  created() {
    this.getOverview({ period: this.overviewPeriod });
    this.getEventTimes({ period: this.eventTimesPeriod });
  },
  methods: {
    getOverview({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      console.log(start_date, end_date)
      dashboard
        .summary(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.overview.data = data;
            this.overviewPeriod = period;
          }
          this.overview.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getEventTimes({ period, from, to }) {
      this.overview.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .eventSummary(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.eventSummary = data;
            this.eventTimesPeriod = period;
          }
          this.overview.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  &:not(:last-child) {
    margin-bottom: 50px !important;
  }
}
</style>
